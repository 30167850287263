<template>
  <div style="border: 1px solid lightgrey; border-radius: 12px;padding: 10px;margin-top: 10px;">
    <section
      v-for="(articles, index) in articleList"
      :key="index"
      class="custom-modal__wrapper"
      style="margin-bottom: 20px; flex-direction: column"
    >
      <div class="paragraphText">
        <h3>{{ articles.articleNumber }}</h3>
        <img
          :src="articles.photos"
          style="max-width: 80px;"
        >
      </div>
      <div>
        <p style="margin: 0px;">
          {{ $t('Location') }}: {{ articles.locationName }}
        </p>
      </div>

      <div class="formInput">
        <b-form-group :label="$t('Price')">
          <b-form-input
            v-model.number="articles.sellPrice"
            type="number"
            placeholder="Ex. 100"
            min="0"
            @input="calculatePrice(articles)"
          />
        </b-form-group>
        <b-form-group :label="$t('Discount')">
          <b-form-input
            v-model.number="articles.discount"
            type="number"
            placeholder="Ex. 100"
            min="0"
            :max="articles.sellPrice"
            @input="calculateDiscount(articles)"
          />
        </b-form-group>
        <b-form-group :label="$t('Currency')">
          <b-form-select
            v-model="articles.currency"
            :options="currencies"
            class="currencyStyle"
            @input="calculateFromPercentage(articles)"
          />
        </b-form-group>
        <b-form-group :label="$t('PriceWithDiscount')">
          <b-form-input
            v-model.number="articles.sellPriceWithDiscount"
            type="number"
            placeholder="Ex. 100"
            min="0"
            disabled
            :max="articles.sellPrice"
            @input="calculatePriceWithDiscount(articles)"
          />
        </b-form-group>
      </div>
      <div class="formInput">
        <b-form-group :label="$t('Quantity')">
          <b-form-input
            v-model.number="articles.quantity"
            type="number"
            placeholder="Ex. 100"
            min="0"
          />
        </b-form-group>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  props: ['articleList'],
  data() {
    return {
      currencies: ['€', '$', '%'],
    };
  },
  watch: {
    articleList: {
      handler(newValue) {
        newValue.forEach((article) => {
          if (this.timeoutId) {
            clearTimeout(this.timeoutId);
          }
          this.timeoutId = setTimeout(() => {
            this.resetQuantityIfNegative(article);
          }, 100);
        });
      },
      deep: true,
    },
  },
  methods: {
    resetQuantityIfNegative(article) {
      if (article.quantity < 0 || article.quantity == '') {
        article.quantity = 0;
      }
    },
    calculatePrice(article) {
      if (article.sellPrice < 0) {
        article.sellPrice = 0;
      }
      this.calculateDiscount(article);
      this.calculatePriceWithDiscount(article);
    },
    calculateDiscount(article) {
      if (article.currency == '%') {
        if (article.discount < 0) {
          article.discount = 0;
        }
        if (article.discount > 100) {
          article.discount = 0;
        }
        this.calculatePriceWithDiscount(article);
      } else {
        if (article.discount < 0) {
          article.discount = 0;
        } else if (article.discount > article.sellPrice) {
          article.discount = 0;
        }
        this.calculatePriceWithDiscount(article);
      }
    },
    calculatePriceWithDiscount(article) {
      if (article.currency === '%') {
        if (article.discount > 0) {
          article.sellPriceWithDiscount = article.sellPrice - (article.sellPrice * article.discount) / 100;
        } else {
          article.sellPriceWithDiscount = article.sellPrice;
        }
      } else if (article.currency !== '%') {
        if (article.discount > 0) {
          article.sellPriceWithDiscount = article.sellPrice - article.discount;
        } else {
          article.sellPriceWithDiscount = article.sellPrice;
        }
      }
      if (article.sellPriceWithDiscount > article.sellPrice) {
        article.sellPriceWithDiscount = article.sellPrice;
      }
    },
    calculateFromPercentage(article) {
      this.calculateDiscount(article);
    },
  },
};
</script>

  <style scoped lang="scss">
  .custom-modal__wrapper {
    display: flex;
    justify-content: space-between;
    border: 1px solid lightgray;
    border-radius: 10px;
    padding: 10px;
    margin-top: 10px;
  }
  .paragraphText {
    display: flex;
    gap: 10px;
    align-items: center;
    margin-bottom: 10px;
  }
  .paragraphText h3 {
    color: red;
    width: auto;
    background-color: red;
    color: white;
    font-size: 20px;
    padding: 10px;
    border-radius: 12px;
    margin: 0px;
  }
  .formInput {
    display: flex;
    gap: 10px;
    padding-top: 10px;;
  }
  .currencyStyle {
    display: block;
    padding: 0.469rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  </style>
