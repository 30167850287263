<template>
  <div>
    <div
      class="search-toggle"
    >
      <p>{{ $t('SearchBy') }}</p>
      <div>
        <p
          class="active2"
        >
          {{ $t('ClientName') }}
        </p>
      </div>
    </div>
    <div
      class="searchfilter"
      style="display: flex;gap:10px;"
    >
      <div
        style="display: flex; align-items: center"
      >
        <div>
          <div class="black">
            <b-icon-search
              v-if="searchClient == ''"
              class="search1"
              style="position: absolute; left: 5px; top: 12px"
            />
            <b-icon-x-circle
              v-else-if="searchClient != ''"
              class="search1"
              style="position: absolute; right: 20px; top: 12px; cursor: pointer"
              @click="searchClient = ''"
            />
            <input
              v-model="searchClient"
              type="text"
              autocomplete="off"
              name="search-box"
              :placeholder="$t('ClientName')"
              :style="getFirstNames.length > 0? 'border-radius: 8px 8px 0px 0px' : ''"
              @keyup="inputChanged"
              @keydown.down="onArrowDown"
              @keydown.up="onArrowUp"
            >
          </div>
          <div
            v-if="getFirstNames.length > 0"
            ref="scrollContainer"
            class="dropdrop"
            :style="getFirstNames.length > 7 ? 'height: 210px' : 'height: auto'"
          >
            <div
              v-for="(result, index) in getFirstNames"
              :key="index"
              ref="options"
              style="cursor: pointer"
              class="search-item"
              :class="{ 'is-active': index === arrowCounter }"
              @click="searchByOn(result);"
            >
              <p style="margin: 0">
                {{ result.nameToDisplay }}
              </p>
            </div>
          </div>
          <p
            v-if="!isLoading && searchClient !== '' && getFirstNames.length === 0"
            style="color:red;margin-top: 10px;margin-bottom: 10px"
          >
            {{ $t('NoClientFound') }}
          </p>
        </div>
      </div>
      <b-button
        v-if="clientNew == false"
        type="button"
        variant="none"
        class="buttonSubmit"
        style="width: 290px; height: 40px;"
        @click="addNewClient"
      >
        {{ $t(`Addclient`) }}
      </b-button>
      <b-button
        v-if="clientNew == true"
        type="button"
        variant="none"
        class="buttonSubmit"
        style="width: 290px; height: 40px;"
        @click="resetFields"
      >
        {{ $t(`Remove`) }}
      </b-button>
    </div>
    <section
      v-if="clientNew == true"
      class="custom-modal__wrapper"
      style="border-width: 3px;margin-top:10px;"
    >
      <b-tabs>
        <b-tab
          :title="$t('PersonalInfo')"
          active
        >
          <template #title>
            <strong style="font-weight: 500; color: black">{{ $t("PersonalInfo") }}</strong>
          </template>
          <b-collapse
            id="collapse-345"
            visible
          >
            <div
              class="hello"
              style="padding-top: 20px"
            >
              <b-form-group :label="$t('Salutation')">
                <vue-select
                  v-model="$v.staticForm.ordererDto.salutation.$model"
                  :options="salutation"
                  required
                  :placeholder="$t('SelectSalutation')"
                  :state="validateState('salutation')"
                  aria-describedby="input-1-live-feedback"
                />
                <p
                  v-if="staticForm.ordererDto.salutation == null && isSubmitClicked == true"
                  style="color: red"
                >
                  {{ $t('This_is_a_required_field') }}
                </p>
              </b-form-group>

              <b-form-group :label="`${$t('FirstName')}`">
                <b-form-input
                  v-model="$v.staticForm.ordererDto.firstName.$model"
                  :state="validateState('firstName')"
                  placeholder="e.g. Michael"
                  aria-describedby="input-1-live-feedback"
                />
                <b-form-invalid-feedback id="input-1-live-feedback">{{
                  $t('This_is_a_required_field_and_must_be_at_least_3_letters')
                }}</b-form-invalid-feedback>
              </b-form-group>

              <b-form-group :label="`${$t('LastName')}`">
                <b-form-input
                  v-model="$v.staticForm.ordererDto.lastName.$model"
                  :state="validateState('lastName')"
                  placeholder="e.g. Goodman"
                  aria-describedby="input-1-live-feedback"
                />
                <b-form-invalid-feedback id="input-1-live-feedback">{{
                  $t('This_is_a_required_field_and_must_be_at_least_3_letters')
                }}</b-form-invalid-feedback>
              </b-form-group>
            </div>

            <div class="hello">

              <b-form-group :label="`${$t('Gender')}`">
                <vue-select
                  v-model="$v.staticForm.ordererDto.gender.$model"
                  :options="[$t('Male'), $t('Female')]"
                  :placeholder="$t('SelectGender')"
                  required
                  :state="validateState('gender')"
                  aria-describedby="input-5-live-feedback"
                />
                <p
                  v-if="staticForm.ordererDto.gender == null && isSubmitClicked == true"
                  style="color: red"
                >
                  {{ $t('This_is_a_required_field') }}
                </p>
              </b-form-group>

              <b-form-group :label="`${$t('Email')}`">
                <b-form-input
                  v-model="$v.staticForm.ordererDto.email.$model"
                  :state="validateState('email')"
                  placeholder="e.g. example@digitbusiness.ch"
                  aria-describedby="input-1-live-feedback"
                />
                <b-form-invalid-feedback id="input-1-live-feedback">
                  {{
                    $t('Must_be_email_format')
                  }}
                </b-form-invalid-feedback>
              </b-form-group>

              <b-form-group :label="`${$t('PhoneNumber')}`">
                <vue-phone-number-input
                  v-model="staticForm.ordererDto.telephoneNumber"
                  :default-country-code="staticForm.ordererDto.countryCode"
                  :preferred-countries="['CH', 'DE', 'AL']"
                  :error="isPhoneNumberValid === false"
                  @update="getFormattedNumber"
                />
              </b-form-group>
            </div>

            <b-form-group :label="`${$t('OrderType')}`">
              <b-form-select
                v-model="selectedOrderType"
                :class="{ f: error.oType != '' }"
                dropup
                :options="transformOrderTypes"
                required
                aria-describedby="input-1-live-feedback"
                style="display: block;
                          width: 67%;
                          padding: 0.469rem 0.75rem;
                          font-size: 1rem;
                          font-weight: 400;
                          line-height: 1.5;
                          color: #212529;
                          background-color: #fff;
                          background-clip: padding-box;
                          border: 1px solid #ced4da;
                          border-radius: 0.25rem;
                          transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;"
              />
              <p
                v-if="error.oType != ''"
                style="margin-top: 0.25rem;
                            font-size: 0.875em;
                            color: #dc3545;"
              >{{ $t(`${error.oType}`) }}</p>
            </b-form-group>
          </b-collapse>
        </b-tab>
        <b-tab
          :title="$t('BillingAddress')"
        >
          <template #title>
            <strong style="font-weight: 500; color: black">{{ $t("BillingAddress") }}</strong>
          </template>
          <b-collapse
            id="collapse-345"
            visible
          >
            <div
              class="hello"
              style="padding-top: 20px"
            >
              <b-form-group :label="`${$t('Street')}`">
                <b-form-input
                  v-model="staticForm.billingAddressDto.street"
                  placeholder="e.g. Rexhep Maja"
                  aria-describedby="input-1-live-feedback"
                />
                <b-form-invalid-feedback id="input-1-live-feedback">{{
                  $t('This_is_a_required_field_and_must_be_at_least_3_letters')
                }}</b-form-invalid-feedback>
              </b-form-group>

              <b-form-group :label="`${$t('HouseNumber')}`">
                <b-form-input
                  v-model="staticForm.billingAddressDto.houseNumber"
                  type="number"
                  step="any"
                  min="0"
                  placeholder="e.g. 30"
                  aria-describedby="input-1-live-feedback"
                />
                <b-form-invalid-feedback id="input-1-live-feedback"> {{
                  $t('This_is_a_required_field_and_must_contains_numbers')
                }}
                </b-form-invalid-feedback>
              </b-form-group>

              <b-form-group :label="`${$t('DoorNumber')}`">
                <b-form-input
                  v-model="staticForm.billingAddressDto.doorNumber"
                  type="number"
                  placeholder="e.g. 50"
                  step="any"
                  min="0"
                  aria-describedby="input-1-live-feedback"
                />
                <b-form-invalid-feedback id="input-1-live-feedback">{{
                  $t('This_is_a_required_field_and_must_contains_numbers')
                }}
                </b-form-invalid-feedback>
              </b-form-group>
            </div>
            <div class="hello">

              <b-form-group :label="`${$t('PostalCode')}`">
                <b-form-input
                  v-model="staticForm.billingAddressDto.postalCode"
                  min="0"
                  placeholder="e.g. 10000"
                  aria-describedby="input-1-live-feedback"
                />
                <b-form-invalid-feedback id="input-1-live-feedback">{{
                  $t('This_is_a_required_field_and_must_be_at_least_3_letters')
                }}</b-form-invalid-feedback>
              </b-form-group>

              <b-form-group :label="`${$t('City')}`">
                <b-form-input
                  v-model="staticForm.billingAddressDto.city"
                  placeholder="e.g. Prishtinë"
                  aria-describedby="input-1-live-feedback"
                />
                <b-form-invalid-feedback id="input-1-live-feedback">{{
                  $t('This_is_a_required_field_and_must_be_at_least_3_letters')
                }}</b-form-invalid-feedback>
              </b-form-group>

              <b-form-group :label="`${$t('Country')}`">
                <b-form-input
                  v-model="staticForm.billingAddressDto.country"
                  placeholder="e.g. Kosovë"
                  aria-describedby="input-1-live-feedback"
                />
                <b-form-invalid-feedback id="input-1-live-feedback">{{
                  $t('This_is_a_required_field_and_must_be_at_least_3_letters')
                }}</b-form-invalid-feedback>
              </b-form-group>
            </div>
          </b-collapse>
        </b-tab>
        <b-tab
          :title="$t('ShippingAddress')"
        >
          <template #title>
            <strong style="font-weight: 500; color: black">{{ $t("ShippingAddress") }}</strong>
          </template>
          <b-collapse
            id="collapse-345"
            visible
          >
            <div>
              <div
                class="hello"
                style="padding-top: 20px"
              >
                <b-form-group :label="`${$t('Street')}`">
                  <b-form-input
                    v-model="staticForm.shippingAddressDto.street"
                    :disabled="pickUpInStore"
                    placeholder="e.g. Rexhep Maja"
                    aria-describedby="input-1-live-feedback"
                  />
                  <b-form-invalid-feedback>{{
                    $t('This_is_a_required_field_and_must_be_at_least_3_letters')
                  }}</b-form-invalid-feedback>
                </b-form-group>

                <b-form-group :label="`${$t('HouseNumber')}`">
                  <b-form-input
                    v-model="staticForm.shippingAddressDto.houseNumber"
                    :disabled="pickUpInStore"
                    placeholder="e.g. 30"
                    type="number"
                    step="any"
                    min="0"
                    aria-describedby="input-1-live-feedback"
                  />
                  <b-form-invalid-feedback id="input-1-live-feedback">{{
                    $t('This_is_a_required_field_and_must_contains_numbers')
                  }}
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group :label="`${$t('DoorNumber')}`">
                  <b-form-input
                    v-model="staticForm.shippingAddressDto.doorNumber"
                    :disabled="pickUpInStore"
                    type="number"
                    placeholder="e.g. 50"
                    step="any"
                    min="0"
                    aria-describedby="input-1-live-feedback"
                  />
                  <b-form-invalid-feedback id="input-1-live-feedback">{{
                    $t('This_is_a_required_field_and_must_contains_numbers')
                  }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </div>
              <div class="hello">

                <b-form-group :label="`${$t('PostalCode')}`">
                  <b-form-input
                    v-model="staticForm.shippingAddressDto.postalCode"
                    :disabled="pickUpInStore"
                    placeholder="e.g. 10000"
                    min="0"
                    aria-describedby="input-1-live-feedback"
                  />
                  <b-form-invalid-feedback id="input-1-live-feedback">{{
                    $t('This_is_a_required_field_and_must_be_at_least_3_letters')
                  }}</b-form-invalid-feedback>
                </b-form-group>

                <b-form-group :label="`${$t('City')}`">
                  <b-form-input
                    v-model="staticForm.shippingAddressDto.city"
                    :disabled="pickUpInStore"
                    placeholder="e.g. Prishtinë"
                    aria-describedby="input-1-live-feedback"
                  />
                  <b-form-invalid-feedback id="input-1-live-feedback">{{
                    $t('This_is_a_required_field_and_must_be_at_least_3_letters')
                  }}</b-form-invalid-feedback>
                </b-form-group>

                <b-form-group :label="`${$t('Country')}`">
                  <b-form-input
                    v-model="staticForm.shippingAddressDto.country"
                    :disabled="pickUpInStore"
                    placeholder="e.g. Kosovë"
                    aria-describedby="input-1-live-feedback"
                  />
                  <b-form-invalid-feedback id="input-1-live-feedback">{{
                    $t('This_is_a_required_field_and_must_be_at_least_3_letters')
                  }}</b-form-invalid-feedback>
                </b-form-group>
              </div>
              <span
                class="duplicate-material"
                @click="duplicateBillingAddress(), resetPickUpInStore()"
              >
                {{ $t('SameAsBillingAddress') }}
              </span>
            </div>
            <p>
              <b-form-checkbox
                id="checkbox-1"
                v-model="pickUpInStore"
                name="checkbox-1"
                :value="true"
                :unchecked-value="false"
                @input="resetShippingAddress()"
              >
                {{ $t('GetInStore') }}
              </b-form-checkbox>
            </p>
          </b-collapse>
        </b-tab>
      </b-tabs>
    </section>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { validationMixin } from 'vuelidate';
import {
  required, minLength, email,
} from 'vuelidate/lib/validators';


export default {
  mixins: [validationMixin],
  data() {
    return {
      clientNew: false,
      isSubmitClicked: false,
      error: {
        oType: '',
        duplicateError: '',
      },
      body: {},
      searchClient: '',
      skipWatch: false,
      enterPressed: false,
      arrowCounter: 0,
      isLoading: false,
      clientId: '00000000-0000-0000-0000-000000000000',
      salutation: ['Mr.', 'Ms.', 'BA.', 'BSc.', 'DI.', 'MA.', 'MSc.', 'MBA.', 'PhD.'],
      pickUpInStore: false,
      isPhoneNumberValid: null,
      staticForm: {
        ordererDto: {
          salutation: null,
          firstName: '',
          lastName: '',
          email: '',
          gender: null,
          telephoneNumber: '',
          countryCode: '',
        },
        billingAddressDto: {
          street: '',
          houseNumber: '',
          doorNumber: '',
          postalCode: '',
          city: '',
          country: '',
        },
        shippingAddressDto: {
          street: '',
          houseNumber: '',
          doorNumber: '',
          postalCode: '',
          city: '',
          country: '',
        },
      },
      selectedOrderType: null,
    }
  },
  validations: {
    selectedOrderType: {
      required,
    },
    staticForm: {
      ordererDto: {
        salutation: {
          required,
        },
        firstName: {
          required,
          minLength: minLength(3),
        },
        lastName: {
          required,
          minLength: minLength(3),
        },
        email: {
          email,
        },
        gender: {
          required,
        },
      },
    },
  },
  computed: {
    ...mapGetters(['getFirstNames', 'getClient', 'getOrderTypes']),
    transformOrderTypes() {
      const allOption = { value: null, text: `${this.$t('SelectOrderType')}`, orderType: 'null' };
      const orderTypes = this.getOrderTypes.map((order) => {
        return {
          value: order.orderType,
          text: order.orderType,
          orderType: order.orderType,
        }
      });
      return [allOption, ...orderTypes];
    },
  },
  watch: {
    selectedOrderType(value) {
      if (value !== null) {
        this.error.oType = ''
        const object = {
          clientId: this.clientId,
          ...this.staticForm,
          pickUpInStore: this.pickUpInStore,
        }
        this.$emit('sentClientData', object)
      }
    },
    pickUpInStore(value) {
      if (value == true) {
        this.staticForm.shippingAddressDto.street = '';
        this.staticForm.shippingAddressDto.houseNumber = '';
        this.staticForm.shippingAddressDto.doorNumber = '';
        this.staticForm.shippingAddressDto.postalCode = '';
        this.staticForm.shippingAddressDto.city = '';
        this.staticForm.shippingAddressDto.country = '';
        const object = {
          clientId: this.clientId,
          ...this.staticForm,
          pickUpInStore: this.pickUpInStore,
        }
        this.$emit('sentClientData', object)
      }
    },
    staticForm(value) {
      if (value) {
        const object = {
          clientId: this.clientId,
          ...this.staticForm,
          pickUpInStore: this.pickUpInStore,
        }
        this.$emit('sentClientData', object)
      }
    },
    searchClient(value, oldValue) {
      this.isLoading = true;
      if (this.skipWatch) {
        this.skipWatch = false;
        return;
      }
      if (oldValue.length > this.searchClient.length) {
        this.resetClientId()
        this.clientId = '00000000-0000-0000-0000-000000000000'
        this.resetCustomerData()
      }
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (value === '') {
          this.removeLists();
        } else if (!this.enterPressed) {
          this.isLoading = true;
          this.firstNames(value)
            .finally(() => {
              this.isLoading = false;
            });
        }
      }, 500);
    },
  },
  mounted() {
    this.loadOrderTypes()
  },
  methods: {
    ...mapActions(['firstNames', 'loadOrderTypes', 'resetClientId', 'getClientById', 'resetFirstNames']),
    addNewClient() {
      this.clientNew = true
    },
    resetCustomerData() {
      this.staticForm = {
        ordererDto: {
          salutation: null,
          firstName: '',
          lastName: '',
          email: '',
          gender: null,
          telephoneNumber: '',
          countryCode: '',
        },
        billingAddressDto: {
          street: '',
          houseNumber: '',
          doorNumber: '',
          postalCode: '',
          city: '',
          country: '',
        },
        shippingAddressDto: {
          street: '',
          houseNumber: '',
          doorNumber: '',
          postalCode: '',
          city: '',
          country: '',
        },
      }
      this.body = {}
    },
    getFormattedNumber(n) {
      if (!n.number) {
        this.isPhoneNumberValid = null;
        this.staticForm.ordererDto.telephoneNumber = '';
        this.staticForm.ordererDto.countryCode = '';
        return;
      }

      if (n.isValid) {
        this.isPhoneNumberValid = true;
        this.staticForm.ordererDto.telephoneNumber = n.nationalNumber;
        this.staticForm.ordererDto.countryCode = n.countryCode;
      } else {
        this.isPhoneNumberValid = false;
        this.staticForm.ordererDto.telephoneNumber = '';
        this.staticForm.ordererDto.countryCode = '';
      }
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.staticForm.ordererDto[name];
      return $dirty ? !$error : null;
    },
    resetShippingAddress() {
      this.$v.staticForm.shippingAddressDto.$reset()
    },
    resetPickUpInStore() {
      this.pickUpInStore = false
    },
    duplicateBillingAddress() {
      this.staticForm.shippingAddressDto.street = this.staticForm.billingAddressDto.street;
      this.staticForm.shippingAddressDto.houseNumber = this.staticForm.billingAddressDto.houseNumber;
      this.staticForm.shippingAddressDto.doorNumber = this.staticForm.billingAddressDto.doorNumber;
      this.staticForm.shippingAddressDto.postalCode = this.staticForm.billingAddressDto.postalCode;
      this.staticForm.shippingAddressDto.city = this.staticForm.billingAddressDto.city;
      this.staticForm.shippingAddressDto.country = this.staticForm.billingAddressDto.country;
    },
    async searchByOn(value) {
      this.skipWatch = true;
      this.searchClient = value.nameToDisplay;
      this.clientId = value.clientId
      const clientData = await this.getClientById({
        clientId: value.clientId,
        successCallback: () => {
          this.resetFirstNames();
          const object = {
            clientId: this.clientId,
            ...this.staticForm,
            pickUpInStore: this.pickUpInStore,
          }
          this.$emit('sentClientData', object)
        },
      })
      this.clientNew = true
      this.staticForm.ordererDto.salutation = clientData.salutation
      this.staticForm.ordererDto.firstName = clientData.firstName
      this.staticForm.ordererDto.lastName = clientData.lastName
      this.staticForm.ordererDto.gender = clientData.gender
      this.staticForm.ordererDto.email = clientData.email
      this.staticForm.ordererDto.telephoneNumber = clientData.telephoneNumber
      this.staticForm.ordererDto.countryCode = clientData.countryCode
      this.staticForm.ordererDto.taxRate = clientData.taxRate
      this.staticForm.billingAddressDto.street = clientData.billingAddressDto.street
      this.staticForm.billingAddressDto.houseNumber = clientData.billingAddressDto.houseNumber
      this.staticForm.billingAddressDto.doorNumber = clientData.billingAddressDto.doorNumber
      this.staticForm.billingAddressDto.postalCode = clientData.billingAddressDto.postalCode
      this.staticForm.billingAddressDto.city = clientData.billingAddressDto.city
      this.staticForm.billingAddressDto.country = clientData.billingAddressDto.country
      this.staticForm.shippingAddressDto.street = clientData.shippingAddressDto.street
      this.staticForm.shippingAddressDto.houseNumber = clientData.shippingAddressDto.houseNumber
      this.staticForm.shippingAddressDto.doorNumber = clientData.shippingAddressDto.doorNumber
      this.staticForm.shippingAddressDto.postalCode = clientData.shippingAddressDto.postalCode
      this.staticForm.shippingAddressDto.city = clientData.shippingAddressDto.city
      this.staticForm.shippingAddressDto.country = clientData.shippingAddressDto.country
      this.body = clientData.bodyMeasurementDto
      this.$emit('bodyMeasurement', clientData.bodyMeasurementDto)
    },
    onArrowDown(ev) {
      ev.preventDefault()
      if (this.arrowCounter < this.getFirstNames.length - 1) {
        this.arrowCounter += 1;
        this.fixScrolling();
      }
    },
    onArrowUp(ev) {
      ev.preventDefault()
      if (this.arrowCounter > 0) {
        this.arrowCounter -= 1;
        this.fixScrolling()
      }
    },
    inputChanged(event) {
      if (event.code == 'ArrowUp' || event.code == 'ArrowDown') { return; }

      // eslint-disable-next-line no-useless-return
      if (event.code == 'Enter') {
        this.enterPressed = true
        this.searchByOn(this.getFirstNames[this.arrowCounter])
        this.arrowCounter = 0
        setTimeout(() => {
          this.enterPressed = false
        }, 1000)
        // eslint-disable-next-line no-useless-return
        return;
      }
    },
    fixScrolling() {
      const liH = this.$refs.options[this.arrowCounter].clientHeight;
      console.log(liH)
      this.$refs.scrollContainer.scrollTop = liH * this.arrowCounter;
    },
    resetFields() {
      this.resetCustomerData()
      this.clientNew = false
      this.resetFirstNames();
      this.resetClientId()
      this.searchClient = ''
      this.clientId = '00000000-0000-0000-0000-000000000000'
    },
    removeLists() {
      this.clientNew = false
      this.resetFirstNames();
      this.resetClientId()
      this.searchClient = ''
      this.clientId = '00000000-0000-0000-0000-000000000000'
    },
  },
}
</script>

    <style lang="scss" scoped>
    textarea.form-control {
      min-height: calc(1.5em + 0.75rem + 2px);
      width: 1090px;
  }
  fieldset {
      min-width: 0;
      padding: 0;
      margin: 0;
      border: 0;
      width: 49%;
  }
    .custom-modal__wrapper {
    display: flex;
    justify-content: space-between;
    border: 1px solid lightgray;
    border-radius: 10px;
    padding: 10px 0px;

    >div {
      flex: 1;
      margin: 0px 15px;
    }
  }

  .hello{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    gap: 1%
  }

    .search1 {
        padding: 0px;
      }
    .black {
      position: relative;
    }
    .black input {
      height: 38px;
      padding: 20px;
    }
    .dropdrop {
    display: inline-block;
    overflow: auto;
    position: absolute;
    background: white;
    width: 227px;
    z-index: 2;
    box-shadow: none;
    }
    .search-item {
      padding: 5px;
      max-width: 500px;
      width: 100%;
      font-weight: 400;
      color: #82868c;
      font-size: 1rem;
      border-bottom: 1px solid #e8e8e8;
    }
    .search-item:last-child {
      border-bottom: none;
    }
    .search-item:hover {
      background: #82868c;
      color: white;
    }
    .search-item hr {
      color: lightgray;
      border-top: none;
      margin: -1.3rem 0 0.5rem 0;
    }
    .is-active {
      background-color: #dedede;
    }





    </style>
