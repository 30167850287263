<template>
  <b-modal
    id="create-order-articles"
    ref="modal"
    header-class="headerModal"
    centered
    size="xl"
    title="Create order modal"
    hide-footer
  >
    <ClientSearch
      @sentClientData="sentClientDatas"
    />
    <ArticleSell
      :article-list="articleList"
    />
    <div style="margin-left:5px;margin-top: 10px;width:100%;border:3px solid lightgray;border-radius: 6px;padding:15px">
      <b-form-group
        :label="$t('TotalPrice')"
        style="margin-top:5px;margin-bottom:5px;width:30%"
      >
        <b-form-input
          v-model.number="totalPrice"
          :placeholder="$t('Total Price')"
          type="number"
          disabled
        />
      </b-form-group>
    </div>
    <div style="margin-left:5px;margin-top: 10px;width:100%;border:3px solid lightgray;border-radius: 6px;padding:15px">
      <b-form-group
        :label="$t('ShippingCost')"
        style="width:30%"
      >
        <div
          style="display:flex;flex-direction:column;gap:5px;"
        >
          <div style="display:flex;flex-direction:row;gap:5px;">
            <b-form-input
              v-model.number="shippings.cost"
              :placeholder="$t('Cost')"
              type="number"
            />
            <b-form-select
              v-model="shippings.currency"
              dropup
              :options="[{ value: null, text: 'None' }, ...currencies]"
              aria-placeholder="Select order type"
              aria-describedby="input-1-live-feedback"
              style="display: block;
                        padding: 0.469rem 0.75rem;
                        font-size: 1rem;
                        font-weight: 400;
                        line-height: 1.5;
                        color: #212529;
                        background-color: #fff;
                        background-clip: padding-box;
                        border: 1px solid #ced4da;
                        border-radius: 0.25rem;
                        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;"
              :disabled="shippings.cost == null || shippings.cost == ''"
            />
          </div>
          <b-form-group
            :label="$t('paidAmount')"
            style="width: 100%"
          />
          <b-form-input
            v-model.number="shippings.paidAmount"
            :placeholder="$t('paidAmount')"
            type="number"
            :disabled="shippings.cost == null || shippings.cost == ''"
          />
          <b-form-group
            :label="$t('Notes')"
            style="width:100%"
          >
            <b-form-textarea
              v-model="shippings.notes"
              style="width:100%"
              :placeholder="$t('Select')"
              type="number"
            />
          </b-form-group>
        </div>
      </b-form-group>
      <b-form-group
        :label="$t('SelectAccount')"
        style="margin-top:5px;margin-bottom:5px;width:30%"
      >
        <vue-select
          v-model="shippings.accountId"
          :options="getAccountDetails"
          required
          label="name"
          :reduce="(account) => account.accountId"
          :placeholder="$t('SelectAccount')"
          aria-describedby="input-1-live-feedback"
          :disabled="(shippings.cost == null || shippings.cost == '') || (shippings.paidAmount == null || shippings.paidAmount == '')"
        />
        <p
          v-if="shippings.cost != null && shippings.paidAmount != null && shippings.accountId == null"
          style="color:red"
        >{{ $t('FillData') }}</p>
      </b-form-group>
    </div>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ClientSearch from './modals/ClientSearch.vue';
import ArticleSell from './modals/ArticleSell.vue';


export default {
  components: {
    ClientSearch,
    ArticleSell,
  },
  props: ['articleList'],
  data() {
    return {
      clientData: null,
      shippings: {
        cost: null,
        accountId: null,
        currency: '€',
        notes: '',
        paidAmount: null,
      },
      currencies: ['€', '$', 'CHF'],
    }
  },
  computed: {
    ...mapGetters(['getAccountDetails']),
    totalPrice() {
      return this.articleList.reduce((total, article) => {
        return total + ((article?.sellPriceWithDiscount || 0) * (article?.quantity || 0));
      }, 0);
    },
  },
  mounted() {
    this.accountDetails(
      {
        isActive: true,
        bankId: null,
      },
    )
  },
  methods: {
    ...mapActions(['accountDetails']),
    sentClientDatas(value) {
      this.clientData = value
    },
  },
}
</script>

  <style>

  </style>
